import React, { Component } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import axios from '../axios'

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetchedData: [],
    }
  }

  getNotifications() {
    axios
      .get('/get/notifications', {})
      .then(res => {
        const data = res.data
        this.setState({
          fetchedData: data,
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidMount() {
    this.getNotifications()
    this.interval = setInterval(() => this.getNotifications(), 10000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { fetchedData } = this.state
    return (
      <>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.notifications.title' />
        </div>
        {fetchedData.filterchng === 'true' && (
          <div className='interfaceRow'>
            - <FormattedMessage id='pages.notifications.filterchange' />
          </div>
        )}
        {fetchedData.roomairsensor === 'true' && (
          <div className='interfaceRow'>
            - <FormattedMessage id='pages.notifications.roomair' />
          </div>
        )}
        {fetchedData.fanfault === 'true' && (
          <div className='interfaceRow'>
            - <FormattedMessage id='pages.notifications.fanfaulty' />
          </div>
        )}
        {fetchedData.iofault === 'true' && (
          <div className='interfaceRow'>
            - <FormattedMessage id='pages.notifications.iofaulty' />
          </div>
        )}
        {fetchedData.networklost === 'true' && (
          <div className='interfaceRow'>
            - <FormattedMessage id='pages.notifications.network' />
          </div>
        )}
        {fetchedData.maintreq === 'true' && (
          <div className='interfaceRow'>
            - <FormattedMessage id='pages.notifications.maintenance' />
          </div>
        )}
      </>
    )
  }
}

export default Notifications
